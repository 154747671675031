import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { i18nextPlugin } from "translation-check";

import nsAction from "@config/i18n/_action/en.json";
import nsCommon from "@config/i18n/_common/en.json";
import nsMessages from "@config/i18n/_messages/en.json";
import nsDataLibrary from "@config/i18n/dataLibrary/en.json";
import nsExtendView from "@config/i18n/extendView/en.json";
import nsFilters from "@config/i18n/filters/en.json";
import nsHome from "@config/i18n/home/en.json";
import nsNavigation from "@config/i18n/navigation/en.json";
import nsProfile from "@config/i18n/profile/en.json";

export const defaultNS = "_common";
const fallbackNS = defaultNS;

// The NS's listed below will always be imported
// Only include the NS's that common the rest will be...
// imported dynamically on-demand
export const namespaces = [
  "_action",
  "_common",
  "_messages",
  "home",
  "navigation",
  "extendView",
  "filters",
  "profile",
  "dataLibrary",
];

export const resources = {
  en: {
    _common: nsCommon,
    _action: nsAction,
    _messages: nsMessages,
    home: nsHome,
    navigation: nsNavigation,
    extendView: nsExtendView,
    filters: nsFilters,
    profile: nsProfile,
    dataLibrary: nsDataLibrary,
  },
} as const;

const config = {
  lng: "en",
  fallbackLng: "en",
  ns: namespaces,
  defaultNS,
  fallbackNS,
  resources,
  interpolation: {
    formatSeparator: ",",
    escapeValue: false, // not needed for react as it escapes by default
    skipOnVariables: false, // enable nested translation
  },
  partialBundledLanguages: true,
  debug: import.meta.env.REACT_APP_DEBUG_I18NEXT === "true",
};

/* @vite-ignore */
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(i18nextPlugin)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`../../config/src/i18n/${namespace}/${language}.json`)
    )
  )
  .use(initReactI18next)
  .init(config)
  .catch((e) => {
    console.error("I18n init error", e);
  });

export default i18n;
