/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, flow, makeAutoObservable, observable } from "mobx";
import { DateTime } from "luxon";

import {
  blkReader,
  collectorBlockPath,
  getValueFromBlock,
  NETWORK_BLOCK_TYPES,
  toBlkMonth,
} from "@conf/blocks";
import { logger as baseLogger } from "@core/logger";
import { getUrlParam, insertUrlParam, removeUrlParam } from "@core/utils";
import { generateSpecs } from "@core/utils/columns/utils";
import type { rootStore } from "@stores/root_store";

import faultDetectionColumns from "./FaultDetection.columns";
import { getFaultLevel } from "./getFaultLevel";
import { getNoteCell } from "./getNoteCell";

const logger = baseLogger.getSubLogger({
  name: `FaultDetectionStore`,
});

export class FaultDetectionStore {
  parent: typeof rootStore;
  loading = true;

  ready = false;

  // These should be private when TypeScript is added
  dataFetched = false;

  blockData = null;

  meterData = null;

  faultData: any = null;

  latestDataUpload: {
    value: DateTime | null;
    errorMessage: string | null;
  } = {
    value: null,
    errorMessage: null,
  };

  lpMonth: DateTime | null = null;

  faults: {
    substations: [{ uid: string; faults?: [string]; ignore_faults?: boolean }] | [];
    errorMessage: string | null;
  } = {
    substations: [],
    errorMessage: null,
  };

  tableData: {
    data: { [key: string]: any }[];
    allRow: { [key: string]: any }[];
    errorMessage: string | null;
  } = {
    data: [],
    errorMessage: null,
    allRow: [],
  };

  fromDate: DateTime | null = null;

  toDate: DateTime | null = null;

  minDate: DateTime | null = null;

  maxDate: DateTime | null = null;

  filterState = "Relevant";

  // Non Observables
  formattedColumns: [{ [key: string]: any }] | [] = [];

  isTriggerFetch = false;

  constructor(parent: typeof rootStore) {
    this.parent = parent;

    makeAutoObservable(
      this,
      {
        formattedColumns: false,
        columns: false,
        blockData: false,
        meterData: false,
        faultData: false,
        faults: false,
        tableData: observable.ref,
        processData: flow.bound,
        getData: flow.bound,
        isTriggerFetch: observable,
        toggleTriggerFetch: action,
      },
      { autoBind: true }
    );
  }

  init() {
    if (this.ready) return;

    logger.debug("Initializing...");
    const { meteringLatestUpload, LastProcessedYear } = this.parent.networks;
    const startDateFromParam = DateTime.fromISO(getUrlParam("start_date") ?? "");
    const endDateFromParam = DateTime.fromISO(getUrlParam("end_date") ?? "");

    if (LastProcessedYear.isValid) {
      // This is the end of the year, but it includes the whole year
      this.minDate = LastProcessedYear.set({ month: 1, day: 1 });
    }

    const DEFAULT_DAY_RANGE = 7;
    const MAX_DAY_RANGE = 31;

    this.maxDate = this.minDate ? this.minDate.plus({ days: DEFAULT_DAY_RANGE }) : DateTime.now();
    if (meteringLatestUpload?.isValid) {
      this.maxDate = meteringLatestUpload;
    }

    // This looks complex, but it isnt
    // The maximum difference between the two dates is MAX_DAY_RANGE days
    if (startDateFromParam.isValid && endDateFromParam.isValid) {
      // Use both url params if they are valid
      this.fromDate = startDateFromParam;
      this.toDate = endDateFromParam;
    } else if (startDateFromParam.isValid) {
      // Otherwise if only one is valid, use that
      // and set the other to -MAX_DAY_RANGE days from the valid one,
      // or + MAX_DAY_RANGE days from the valid one
      this.fromDate = startDateFromParam;
      this.toDate = startDateFromParam.plus({ days: MAX_DAY_RANGE });
    } else if (endDateFromParam.isValid) {
      this.fromDate = endDateFromParam.minus({ days: MAX_DAY_RANGE });
      this.toDate = endDateFromParam;
    } else if (this.maxDate) {
      // If no dates are in the url, use max date - DEFAULT_DAY_RANGE days
      this.fromDate = this.maxDate.minus({ days: DEFAULT_DAY_RANGE });
      this.toDate = this.maxDate;
    }

    // Then finally if the dates are outside the min/max range, set them to the min/max range
    if (
      this.fromDate &&
      this.minDate &&
      this.maxDate &&
      (this.fromDate < this.minDate || this.fromDate > this.maxDate)
    ) {
      this.fromDate = this.maxDate ? this.maxDate.minus({ days: MAX_DAY_RANGE }) : this.minDate;
    }

    if (
      this.toDate &&
      this.minDate &&
      this.maxDate &&
      (this.toDate < this.minDate || this.toDate > this.maxDate)
    ) {
      this.toDate = this.maxDate;
    }

    logger.debug("Initialised: ", {
      fromDate: String(this.fromDate),
      toDate: String(this.toDate),
      minDate: String(this.minDate),
      maxDate: String(this.maxDate),
    });

    this.lpMonth = this.parent.networks.LastProcessedYear;
    // @ts-expect-error typing infoblocks are still a chaos
    this.formattedColumns = faultDetectionColumns.map((c) => ({
      ...c,
      blockName:
        // @ts-expect-error typing infoblocks are still a chaos
        typeof c.blockName === "function"
          ? // @ts-expect-error typing infoblocks are still a chaos
            c.blockName(this.lpMonth.year, toBlkMonth(this.lpMonth.month))
          : // @ts-expect-error typing infoblocks are still a chaos
            c.blockName,
    }));
    this.ready = true;
    void this.processData();
    this.isTriggerFetch = !!this.isTriggerFetch;
  }

  get currentNetworkId(): string | undefined {
    return this.parent.networks?.current_network?.uid;
  }

  setFilter(filter: string) {
    this.filterState = filter;
    void this.processData();
  }

  // We set both the start and end date here, because the substation explore needs both
  setPeriodUrlParams(fromDate: DateTime, toDate: DateTime) {
    if (this.fromDate?.isValid) insertUrlParam("start_date", String(fromDate.toISODate()));
    if (this.toDate?.isValid) insertUrlParam("end_date", String(toDate.toISODate()));
  }

  setFromDate(date: DateTime, updateParam = true) {
    if (date === null || this.toDate === null) return;

    this.fromDate = date;
    if (this.toDate < this.fromDate) {
      this.toDate = this.fromDate;
    }
    if (updateParam) this.setPeriodUrlParams(this.fromDate, this.toDate);
  }

  setToDate(date: DateTime, updateParam = true) {
    if (date === null || this.fromDate === null) return;

    this.toDate = date;
    if (this.toDate < this.fromDate) {
      this.fromDate = this.toDate;
    }
    if (updateParam) this.setPeriodUrlParams(this.fromDate, this.toDate);
  }

  getBlockNames() {
    // Block names to be fetched
    const blockNames = this.formattedColumns.filter((c) => c.blockName).map((c) => c.blockName);
    // Remove duplicates
    return [...new Set(blockNames)];
  }

  getData = flow(function* getData(this: FaultDetectionStore, substationIds = []) {
    logger.debug("getData called");
    this.loading = true;
    this.dataFetched = false;

    // Then we get the fault data
    try {
      this.faultData = yield this.parent.faultDetectionApi.getFaultsForNetwork(
        this.currentNetworkId,
        this.fromDate,
        this.toDate,
        substationIds
      );
    } catch (error) {
      this.parent.notifications.error("Failed to get fault data");
      this.faultData = null;
    }

    // Then we get the additional blocks to generate the table for the "network_substations"
    try {
      let data;
      if (substationIds.length === 1) {
        //! I wish I was joking when I say that there is no way to get the block data for multiple substations, other than all
        data = yield this.parent.newapi.getInfoBlocksV4({
          resource_type: "substation",
          resource_id: substationIds[0],
          block_names: this.getBlockNames(),
        });
      } else {
        data = yield this.parent.newapi.getInfoBlocksV4({
          resource_type: "network_substations",
          resource_id: this.currentNetworkId,
          block_names: this.getBlockNames(),
        });
      }

      // TODO !!Revisit this
      // @ts-expect-error DateTime.year is not a function
      const { year, month } = this.lpMonth;
      const accessor = `ep_${year}-${toBlkMonth(month)}_core_1000`;
      this.blockData = {
        ...data,
        [accessor]: {
          ...data[accessor],
          columns: {
            ...data[accessor].columns,
            ep_heat_energy_sum: data[accessor].columns.heat_energy_sum,
            ep_supplytemp_flowweighted_avg: data[accessor].columns.supplytemp_flowweighted_avg,
            ep_returntemp_flowweighted_avg: data[accessor].columns.returntemp_flowweighted_avg,
          },
        },
      };
    } catch (error) {
      logger.error("Failed to get block data", error);
      this.blockData = null;
    }

    // Finally we set the dataFetched flag to true
    this.dataFetched = true;
    yield this.processData();
  });

  get columns() {
    return [
      {
        id: "id",
        accessorKey: "id",
        header: "text_substation_id",
        type: "link",
        info: "text_substation_id_desc",
        translateNs: "_common",
        isVisible: true,
        hideable: false,
        onClick: (subId: string) => {
          this.parent.sub.updateCurrentSubstation(subId);
          // Open "Fault Detection" tab in explorer with last week filter
          insertUrlParam("explore_tab", "fault_detection");
          insertUrlParam("end_date", String(this.toDate?.toISODate() ?? ""));
          insertUrlParam("start_date", String(this.fromDate?.toISODate() ?? ""));
          insertUrlParam("investigate", "true");
          removeUrlParam("status_updated");
          this.parent.ui.openSubsummary();
          this.parent.ui.openSubDetail();
        },
        size: 180,
      },
      ...this.formattedColumns,
    ];
  }

  processData = flow(function* processData(this: FaultDetectionStore) {
    logger.debug("processData called");
    // Lets first get the meterdata for the "network", because we need the last processed month
    try {
      this.meterData = yield this.parent.newapi.getInfoBlocksV4({
        resource_type: "network",
        resource_id: this.currentNetworkId,
        block_names: [NETWORK_BLOCK_TYPES.metering_latest_upload.to_block_name()],
      });
    } catch (error) {
      this.parent.notifications.error("Failed to fetch meter data");
      this.meterData = null;
    }
    // Output fields
    const latestDataUpload: typeof this.latestDataUpload = {
      value: null,
      errorMessage: null,
    };
    const faults: typeof this.faults = {
      substations: [],
      errorMessage: null,
    };
    const tableData: typeof this.tableData = {
      data: [],
      errorMessage: null,
      allRow: [],
    };

    // If we havent fetched data yet, return empty values
    if (this.dataFetched) {
      // Set fields dependant on meterdata
      if (this.meterData) {
        latestDataUpload.value = getValueFromBlock(
          this.meterData,
          "metering_latest_upload",
          this.currentNetworkId,
          "processed_time"
        );
        if (latestDataUpload.value) {
          // Set timezone to network timezone
          latestDataUpload.value = latestDataUpload.value.setZone(
            this.parent.networks.network_timezone
          );
          latestDataUpload.errorMessage = "";
        } else {
          latestDataUpload.errorMessage = "No data uploaded";
        }
      } else {
        latestDataUpload.errorMessage = "Failed to get meter data";
      }

      // Set fields dependant on faultdata
      if (this.faultData) {
        faults.substations = this.faultData.data.collection;
      } else {
        faults.errorMessage = "Failed to get fault data";
      }

      // Set tableData
      if (this.blockData) {
        // prepare data model from block_data by colSpecs
        const reader = blkReader(
          this.blockData,
          generateSpecs(this.formattedColumns, this.lpMonth),
          collectorBlockPath
        );

        const dataRows: { [key: string]: any }[] = [];
        const allData: { [key: string]: any }[] = [];
        const idOfSubstations: string[] = [];
        const activeSubKeys = Array.from(this.parent.networks.active_substations.keys());
        const activeFaultSubs = faults.substations.filter((sub) => activeSubKeys.includes(sub.uid));
        const yearlyHour = (year: number) => (DateTime.local(year).isInLeapYear ? 8784 : 8760);

        activeFaultSubs.forEach((sub) => {
          const subUid = sub.uid;
          const row = reader(subUid) as { [key: string]: any };
          row.sub_id = subUid;
          row.id = this.parent.networks.active_substations.get(subUid);
          // Assign fault info
          row.fault_detection = {
            supplytemp_dipp: getFaultLevel("ft", sub?.faults?.slice()),
            Returntemp: getFaultLevel("fe", sub?.faults?.slice()),
            Flow: getFaultLevel("fv", sub?.faults?.slice()),
          };

          row.fault_detection_ignored = sub?.ignore_faults ? "Ignored" : "Relevant";

          //* --- Custom Columns ---
          // Power Kw
          row.core.average_heat_energy =
            row.core.heat_energy_sum / yearlyHour(this.lpMonth?.year ?? 0);

          // Flow avg
          row.core.flow = row.core.volume_sum / yearlyHour(this.lpMonth?.year ?? 0);

          // Delta temps flow weighted
          row.core.delta_temp_flow_weighted =
            row.core.supplytemp_flowweighted_avg - row.core.returntemp_flowweighted_avg;

          // Delta temps power weighted
          row.core.delta_temp_power_weighted =
            row.core.supplytemp_powerweighted_avg - row.core.returntemp_powerweighted_avg;

          // Delta temps  unweighted
          row.core.delta_temp_unweighted =
            row.core.supplytemp_unweighted_avg - row.core.returntemp_unweighted_avg;

          // Delta temps flow weighted | normal year
          row.epcore_normalized.delta_temp_flowweighted_avg =
            row.epcore_normalized.supplytemp_flowweighted_avg -
            row.epcore_normalized.returntemp_flowweighted_avg;
          //* ---------------------
          allData.push(row);
          if (this.filterState === "All" || this.filterState === row?.fault_detection_ignored) {
            dataRows.push(row);
            idOfSubstations.push(row.sub_id);
          }
        });

        const substationsNote =
          yield this.parent.faultDetectionApi.getSubstationNotes(idOfSubstations);

        // eslint-disable-next-line array-callback-return
        substationsNote.flat(1).map((item: any) => {
          const idxOfDataRows = dataRows.findIndex((ele) => ele.sub_id === item.substation_uid);
          // Check if idxOfDataRows is valid (-1 means no match found)
          if (idxOfDataRows !== -1 && item.note) {
            dataRows[idxOfDataRows].note = getNoteCell(item.note);
          }
        });

        tableData.data = dataRows;
        tableData.allRow = allData;
      } else {
        tableData.errorMessage = "Failed to get block data";
      }
    }

    this.latestDataUpload = latestDataUpload;
    this.faults = faults;
    this.tableData = tableData;
    this.loading = false;
  });

  getSubstationFaultStatus = flow(function* getSubstationFaultStatus(
    this: FaultDetectionStore,
    subId
  ) {
    // Use the api to get the fault status for a substation
    return yield this.parent.faultDetectionApi.getSubstationFaultStatus(subId);
  });

  setSubstationFaultStatus(netId: string, subId: string, status: string) {
    // Use the api to set the fault status for a substation
    this.parent.faultDetectionApi.setSubstationFaultStatus(netId, subId, status);
  }

  toggleTriggerFetch() {
    this.isTriggerFetch = !this.isTriggerFetch;
  }
}
